import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { FormControl } from '@material-ui/core';
import UserSelectionInfoAlert from '../../../components/UserSelectionInfoAlert';
import { useCompany } from '../../../hooks';

const styles = () => ({
  newUserForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  formNames: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  formControl: {
    marginRight: 20,
    marginTop: 20,
  },
  checkboxControl: {
    marginTop: 10,
  },
});

const NewBookerUserCreation = ({
  user,
  subscriptionUser,
  updateUserDetails,
  subscription,
  classes,
  league,
  leagueUser,
  associate,
  associateUser,
}) => {
  const company = useCompany();

  useEffect(() => {
    if (company?.dialcode && company.dialcode !== user.dialCode) {
      updateUserDetails({ dialCode: company.dialcode });
    }
  }, [company, updateUserDetails, user.dialCode]);

  let {
    firstName, lastName, email, phone, teamName, dialCode,
  } = user;

  if (subscription) {
    firstName = subscriptionUser.firstName;
    email = subscriptionUser.email;
    lastName = subscriptionUser.lastName;
    phone = subscriptionUser.phone;
    dialCode = subscriptionUser.dialCode;
    teamName = subscriptionUser.teamName;
  }

  if (league) {
    firstName = leagueUser?.firstName;
    lastName = leagueUser?.lastName;
    email = leagueUser?.email;
    phone = leagueUser?.phone;
    teamName = leagueUser?.teamName;
  }

  if (associate) {
    firstName = associateUser?.firstName;
    lastName = associateUser?.lastName;
    email = associateUser?.email;
    phone = associateUser?.phone;
    teamName = associateUser?.teamName;
  }

  const handleUpdateDetails = (key, value) => {
    if (user[key] !== value) {
      updateUserDetails({ [key]: value });
    }
  };

  return (
    <div>
      <div className="reservation-creation-new-user-wrapper">
        <div className={classes.formNames}>
          <FormControl className={classes.formControl}>
            <PBInput
              id="firstName"
              type="text"
              label="First Name"
              value={firstName}
              onChange={(event) => handleUpdateDetails('firstName', event.target.value)}
              required
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <PBInput
              id="lastName"
              type="text"
              label="Last Name"
              value={lastName}
              onChange={(event) => handleUpdateDetails('lastName', event.target.value)}
              required
            />
          </FormControl>
        </div>
        <div className={classes.formNames}>
          <FormControl className={classes.formControl}>
            <PBInput
              id="email"
              type="email"
              label="Email Address"
              value={email}
              onChange={(event) => handleUpdateDetails('email', event.target.value)}
              required
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <PBInput
              id="teamName"
              type="text"
              label="Team name"
              value={teamName}
              onChange={(event) => handleUpdateDetails('teamName', event.target.value)}
            />
          </FormControl>
        </div>
        <div className={classes.formNames}>
          <FormControl className={classes.formControl}>
            <PBInput
              id="phone"
              type="phone"
              value={{ phone, dialCode: dialCode || '44' }}
              onChange={(event) => handleUpdateDetails('phone', event.target.value)}
              onChangeDialcode={(event) => handleUpdateDetails('dialCode', event.target.value)}
            />
          </FormControl>
        </div>
      </div>
      <UserSelectionInfoAlert newUser booking={false} />
    </div>
  );
};

NewBookerUserCreation.propTypes = {
  subscriptionUser: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    teamName: PropTypes.string,
    phone: PropTypes.string,
    dialCode: PropTypes.string,
  }).isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  subscription: PropTypes.bool.isRequired,
  league: PropTypes.bool.isRequired,
  leagueUser: PropTypes.shape().isRequired,
  associate: PropTypes.bool.isRequired,
  associateUser: PropTypes.shape().isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  user: ownProps.user,
  subscriptionUser: state.subscriptions.userDetailsForSubscription,
  leagueUser: state.leagues.invitedUser,
  associateUser: state.users.associate,
});

export default connect(mapStateToProps, null)(withStyles(styles)(NewBookerUserCreation));
