import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import {
  List, ListItem, ListItemText, Checkbox,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import StripeCheckout from '@pitchbooking-dev/pb-shared/lib/components/Stripe/StripeCheckout';
import { createPendingReservations } from '@pitchbooking-dev/pb-shared/lib/services/reservationsService';
import PaymentStatusRadio from '../../../../components/PaymentStatusRadio';
import * as reservationsActions from '../../../../reducers/reservationsReducer';
import { useCompany } from '../../../../hooks';
import Terminal from '../../../../components/Stripe/Terminal';
import styles from './styles.module.css';

let alertMessage = 'If the booking is being paid for at the point of creation, then select one of the \'Paid\' options above. Otherwise set the payment status to \'Unpaid\'; a payment can later be recorded, or the booking can be added to an invoice.';

const BookingStepperPaymentInfoPicker = ({
  paymentStatus,
  updateReservationPaymentStatus,
  requestManagerReservationCreation,
  updateReservationUserDetails,
  adminNotes,
  requestingReservationsCreation,
  sendNotesToBooker,
  allocations,
}) => {
  const {
    id: companyId,
    stripeId,
  } = useCompany();

  if (allocations.length === 0) {
    alertMessage = 'You have not selected any slots for this booking. Please return to facility selection and try again.';
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.paymentListWrapper}>
        <div>
          <PaymentStatusRadio
            paymentStatus={paymentStatus}
            setPaymentStatus={
              (selectedPaymentStatus) => updateReservationPaymentStatus(selectedPaymentStatus)
            }
          />
        </div>
        <div>
          {paymentStatus === 'CARD_NOT_PRESENT' && (
            <StripeCheckout
              preSubmitAction={async (e) => {
                const res = await createPendingReservations(e.id);
                if (res.error) {
                  throw res.data;
                }
              }}
              transactionType="ORDER"
              buttonText="Record Payment"
              companyStripeId={stripeId}
              redirectUrl={`${window.location.origin}/bookings`}
              metadata={{
                isCardNotPresent: true,
              }}
            />
          )}
          {paymentStatus === 'TERMINAL' && (
            <Terminal
              transactionType="ORDER"
              companyStripeId={stripeId}
              userId={allocations?.[0]?.userId}
              companyId={companyId}
              onSuccess={() => {
                window.location.reload();
              }}
            />
          )}

          {(paymentStatus !== 'CARD_NOT_PRESENT' && paymentStatus !== 'TERMINAL') && (
            <div>
              <FormLabel component="legend">Booking Notes</FormLabel>
              <List>
                <ListItem
                  dense
                  key={1}
                  onClick={(event) => updateReservationUserDetails(
                    { sendNotesToBooker: event.target.checked },
                  )}
                >
                  <Checkbox
                    checked={sendNotesToBooker}
                    tabIndex="-1"
                    disableRipple
                  />
                  <ListItemText primary="Include these notes in the booking confirmation email?" />
                </ListItem>
              </List>
              <TextField
                id="name"
                label="Notes"
                value={adminNotes}
                onBlur={(event) => updateReservationUserDetails({ adminNotes: event.target.value })}
                multiline
                rows="5"
                margin="normal"
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <Alert
          id="alert"
          severity="info"
          className={styles.alert}
        >
          {alertMessage}
        </Alert>
        {paymentStatus !== 'CARD_NOT_PRESENT' && (
          <Button
            id="manager-bookings-confirm-booking"
            style={{ float: 'right' }}
            variant="contained"
            color="primary"
            disabled={requestingReservationsCreation || allocations.length === 0}
            onClick={() => {
              requestManagerReservationCreation();
            }}
          >
            Confirm Booking
          </Button>
        )}
      </div>
    </div>
  );
};

BookingStepperPaymentInfoPicker.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  adminNotes: PropTypes.string.isRequired,
  updateReservationPaymentStatus: PropTypes.func.isRequired,
  requestManagerReservationCreation: PropTypes.func.isRequired,
  updateReservationUserDetails: PropTypes.func.isRequired,
  requestingReservationsCreation: PropTypes.bool.isRequired,
  sendNotesToBooker: PropTypes.bool.isRequired,
  allocations: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  paymentStatus: state.reservation.selectedPaymentStatus,
  requestingReservationsCreation: state.reservation.requestingReservationsCreation,
  sendNotesToBooker: state.reservation.userDetailsForReservation.sendNotesToBooker,
  allocations: state.basket.confirmedAllocations,
});

const mapDispatchToProps = (dispatch) => ({
  updateReservationPaymentStatus: (selectedPaymentStatus) => dispatch(
    reservationsActions.updateReservationPaymentStatus(selectedPaymentStatus),
  ),
  requestManagerReservationCreation: () => dispatch(
    reservationsActions.requestManagerReservationCreation(),
  ),
  updateReservationUserDetails: (keyWithValue) => dispatch(
    reservationsActions.updateReservationUserDetails(keyWithValue),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingStepperPaymentInfoPicker);
