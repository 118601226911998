/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Responsive from 'react-responsive';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { Button, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import pbLogo from '../../images/logo-black.png';
// import pbChristmasLogo from '../../images/logo-black-christmas.png';
import PageRoutes from '../PageRoutes';
import UserMenu from '../UserMenu';
import MenuDrawerStyles from './styles';
import MenuDrawerItem from './MenuDrawerItem';
import MenuDrawerLinkCollapsedMenu from './MenuDrawerLinkCollapsedMenu';
import WarningMessage from '../WarningMessage';
import { useOrigin, usePendingChanges } from '../../hooks';

const MenuDrawer = ({
  currentCompany,
  logoutTrigger, menuDrawerOpen, currentUser,
  updateUserCompany, userCompanies, appBarOnly,
  warningMessageForAdmins, warningMessageForAdminsPersist,
  hasPendingMembershipRequests,
}) => {
  const pending = usePendingChanges();
  const classes = MenuDrawerStyles();
  const theme = useTheme();
  let role = {};
  const token = localStorage.getItem('PBtoken');
  const selectedCompany = localStorage.getItem('selectedCompany');
  if (token !== null) {
    if (userCompanies && userCompanies?.length > 1 && selectedCompany) {
      role = userCompanies.find((obj) => obj.companyId === selectedCompany)?.role;
    } else {
      role = currentUser.managerAccess[0].role;
    }
    if (role === undefined) { logoutTrigger(); }
  }
  const [menuDrawerState, setMenuDrawerState] = useState(
    {
      open: menuDrawerOpen,
      selectedIndex: 0,
      analyticsOpenNestedList: false,
      bookingOpenNestedList: false,
      facilityOpenNestedList: false,
      usersOpenNestedList: false,
      settingsOpenNestedList: false,
      bookingSettingsOpenNestedList: false,
      financeOpenNestedList: false,
      posOpenNestedList: false,
    },
  );
  const [warningMessageOpen, setWarningMessageOpen] = useState(true);

  const setMenuDrawerStateHandler = (keyWithValue) => setMenuDrawerState({
    ...menuDrawerState,
    ...keyWithValue,
  });

  const {
    open,
    selectedIndex,
    analyticsOpenNestedList,
    bookingOpenNestedList,
    facilityOpenNestedList,
    usersOpenNestedList,
    settingsOpenNestedList,
    bookingSettingsOpenNestedList,
    financeOpenNestedList,
    posOpenNestedList,
  } = menuDrawerState;

  const handleLinkListItemClick = (route, index) => {
    push(route);
    setMenuDrawerStateHandler({ selectedIndex: index });
  };

  const handleClick = (item, index) => {
    setMenuDrawerStateHandler({ [item]: !menuDrawerState[item], selectedIndex: index });
  };

  // const isChristmas = moment().isBetween(moment()
  // .month('December').date(1), moment().month('December').date(25));

  const { products, status, company_domains } = currentCompany;
  const isDirectory = status === 'DIRECTORY_APPROVED';

  const { code } = currentCompany;
  const { bookerUrl, env } = useOrigin(company_domains);
  let partnerPageUrl = `${bookerUrl}/partners/${code}`;
  if (company_domains && company_domains.length > 0 && env !== 'LOCAL') {
    const activeDomain = company_domains.find((domain) => domain.isActive === true);
    if (activeDomain) {
      let { domain } = activeDomain;
      if (!domain.startsWith('http://') && !domain.startsWith('https://')) {
        domain = `https://${domain}`;
      }
      partnerPageUrl = domain;
    }
  }

  const paymentsEnabled = products?.facilities === 'ENABLED' || products?.events === 'ENABLED' || products?.paidMemberships === 'ENABLED' || products?.leagues === 'ENABLED';
  const formsEnabled = products?.facilities === 'ENABLED' || products?.events === 'ENABLED' || products?.paidMemberships === 'ENABLED';
  const membershipsEnabled = products?.paidMemberships === 'ENABLED' || products?.facilities === 'ENABLED' || products?.events === 'ENABLED';
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <ConditionallyVisible condition={!appBarOnly}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setMenuDrawerStateHandler({ open: true })}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </ConditionallyVisible>
          <Link href="/" to="/" className={classes.flex}>
            {/* {isChristmas ? (
              <img src={pbChristmasLogo} alt="Pitchbooking Manager Christmas" height="35px" />
            ) : ( */}
            <img src={pbLogo} alt="Pitchbooking Manager" height="35px" />
            {/* )} */}
          </Link>
          <Responsive minDeviceWidth={600}>
            <UserMenu
              currentCompanyName={pending?.company?.name ?? currentCompany.name}
              userCompanies={userCompanies}
              companyLogo={currentCompany.logoUrl}
              logoutTrigger={() => logoutTrigger()}
              updateCompany={(companyId) => updateUserCompany(companyId)}
            />
          </Responsive>
        </Toolbar>
      </AppBar>
      <ConditionallyVisible condition={!appBarOnly}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => setMenuDrawerStateHandler({ open: false })}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root2}
          >
            <ConditionallyVisible condition={role.viewAnalytics}>
              <MenuDrawerItem
                index={0}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleClick('analyticsOpenNestedList', 0)}
                label="Analytics"
                icon="assessmentIcon"
                collapsable
                collapsed={analyticsOpenNestedList}
              />
              <MenuDrawerLinkCollapsedMenu collapsed={analyticsOpenNestedList}>
                <MenuDrawerItem
                  index={0}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('/analytics', 0)}
                  link="/analytics"
                  label="Facilities"
                  product="facilities"
                  hidden={products?.facilities === 'HIDDEN'}
                  locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
                />

                <MenuDrawerItem
                  index={1}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('/event-analytics', 1)}
                  link="/event-analytics"
                  label="Events"
                  product="events"
                  hidden={products?.events === 'HIDDEN'}
                  locked={products?.events === 'DISABLED'}
                />
              </MenuDrawerLinkCollapsedMenu>
            </ConditionallyVisible>

            <ConditionallyVisible condition={role.viewBookings}>
              <MenuDrawerItem
                index={2}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/calendar', 2)}
                link="/calendar"
                label="Daily Calendar"
                icon="dateRangeIcon"
                product="facilities"
                hidden={products?.facilities === 'HIDDEN'}
                locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
              />
              <MenuDrawerItem
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleClick('bookingOpenNestedList', 3)}
                link="/bookings"
                label="Bookings"
                icon="book"
                collapsable
                collapsed={bookingOpenNestedList}
                product="facilities"
                hidden={products?.facilities === 'HIDDEN'}
                locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
              />
              <MenuDrawerLinkCollapsedMenu collapsed={bookingOpenNestedList}>
                <MenuDrawerItem
                  index={3}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('/bookings', 3)}
                  link="/bookings"
                  label="Bookings Table"
                  product="facilities"
                  hidden={products?.facilities === 'HIDDEN'}
                  locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
                />
                <MenuDrawerItem
                  index={5}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('/subscriptions', 5)}
                  link="/subscriptions"
                  label="Subscriptions"
                  product="subscriptions"
                  hidden={products?.subscriptions === 'HIDDEN'}
                  locked={products?.subscriptions === 'DISABLED'}
                />
              </MenuDrawerLinkCollapsedMenu>
            </ConditionallyVisible>

            {/* Finance */}
            <>
              <MenuDrawerItem
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleClick('financeOpenNestedList', 36)}
                label="Finance"
                icon="accountBalanceIcon"
                collapsable
                collapsed={financeOpenNestedList}
                hidden={!paymentsEnabled}
              />
              <MenuDrawerLinkCollapsedMenu collapsed={financeOpenNestedList}>
                <MenuDrawerItem
                  index={37}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('/payments', 37)}
                  link="/payments"
                  label="Payments Table"
                  hidden={!paymentsEnabled || !role.viewPayments}
                />
                <MenuDrawerItem
                  index={38}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('/invoices', 38)}
                  link="/payouts"
                  label="Payouts"
                  hidden={!role.viewPayouts}
                />
                <MenuDrawerItem
                  index={39}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('/invoices', 39)}
                  link="/invoices"
                  label="Invoices / Payment Links"
                  product="invoices"
                  hidden={products?.invoicing === 'HIDDEN' || !role.viewInvoices}
                  locked={products?.invoicing === 'DISABLED'}
                />
              </MenuDrawerLinkCollapsedMenu>
            </>
            {/* Finance End */}

            <MenuDrawerItem
              index={7}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleLinkListItemClick('/events', 7)}
              link="/events"
              label="Events"
              icon="confirmationNumber"
              product="events"
              hidden={products?.events === 'HIDDEN'}
              locked={products?.events === 'DISABLED'}
            />
            <MenuDrawerItem
              index={11}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleLinkListItemClick('/leagues', 10)}
              link="/leagues"
              label="Leagues"
              icon="leagueIcon"
              product="leagues"
              hidden={products?.leagues === 'HIDDEN'}
              locked={products?.leagues === 'DISABLED' || products?.leagues === 'REQUESTED'}
            />
            <ConditionallyVisible condition={role.viewFacilities}>
              <MenuDrawerItem
                index={8}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleClick('facilityOpenNestedList', 8)}
                link="/facilities"
                label="Facilities"
                icon="locationOn"
                collapsable
                collapsed={facilityOpenNestedList}
              />
              <MenuDrawerLinkCollapsedMenu collapsed={facilityOpenNestedList}>
                <MenuDrawerItem
                  index={8}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('facilityOpenNestedList', 8)}
                  link="/facilities"
                  label="Overview"
                />
                <MenuDrawerItem
                  index={9}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('facilityOpenNestedList', 9)}
                  link="/closures"
                  label="Closures"
                  product="facilities"
                  hidden={products?.facilities === 'HIDDEN' || isDirectory}
                  locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
                />
                <MenuDrawerItem
                  index={10}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('facilityOpenNestedList', 10)}
                  link="/schedules"
                  label="Schedules"
                  product="facilities"
                  hidden={products?.facilities === 'HIDDEN'}
                  locked={!pending && (products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED') && !isDirectory}
                />
                {/* <MenuDrawerItem
              index={7}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleLinkListItemClick('facilityOpenNestedList', 7)}
              link="/pricing"
              label="Pricing"
            /> */}
              </MenuDrawerLinkCollapsedMenu>
            </ConditionallyVisible>

            <ConditionallyVisible condition={role.viewUsers}>
              <MenuDrawerItem
                index={12}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleClick('usersOpenNestedList', 12)}
                link="/users"
                label={(
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    Customers
                    <ConditionallyVisible condition={hasPendingMembershipRequests}>
                      <PriorityHigh fontSize="small" style={{ color: '#4581e2' }} />
                    </ConditionallyVisible>
                  </div>
)}
                icon="peopleIcon"
                collapsable
                collapsed={usersOpenNestedList}
              />
              <MenuDrawerLinkCollapsedMenu collapsed={usersOpenNestedList}>
                <MenuDrawerItem
                  index={12}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('usersOpenNestedList', 12)}
                  link="/users"
                  label="Customers List"
                />
                <MenuDrawerItem
                  index={13}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('usersOpenNestedList', 13)}
                  link="/priorityaccess"
                  label="Priority Access"
                  product="facilities"
                  hidden={products?.facilities === 'HIDDEN'}
                  locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
                />
                <MenuDrawerItem
                  index={14}
                  selectedIndex={selectedIndex}
                  handleLinkListItemClick={() => handleLinkListItemClick('usersOpenNestedList', 14)}
                  link="/memberships"
                  product="paidMemberships"
                  locked={!membershipsEnabled}
                  label={(
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      Memberships
                      <ConditionallyVisible condition={hasPendingMembershipRequests}>
                        <PriorityHigh fontSize="small" style={{ color: '#4581e2' }} />
                      </ConditionallyVisible>
                    </div>
)}
                />
              </MenuDrawerLinkCollapsedMenu>
            </ConditionallyVisible>
            <MenuDrawerItem
              index={17}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleLinkListItemClick('settingsOpenNestedList', 17)}
              label="Products"
              link="/products"
              product="facilities"
              icon="storefrontIcon"
              hidden={products?.facilities === 'HIDDEN'}
              locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
            />
            <MenuDrawerItem
              index={35}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => products?.pos === 'ENABLED' && handleClick('posOpenNestedList', 35)}
              label="Point Of Sale"
              link="/point-of-sale"
              product="pos"
              icon="shoppingCartIcon"
              collapsable
              collapsed={posOpenNestedList}
              hidden={products?.pos === 'HIDDEN'}
              locked={products?.pos === 'DISABLED' || products?.pos === 'REQUESTED'}
            />
            <MenuDrawerLinkCollapsedMenu collapsed={posOpenNestedList}>
              <MenuDrawerItem
                index={35}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('posOpenNestedList', 35)}
                label="Point Of Sale"
                link="/point-of-sale"
                product="pos"
                hidden={products?.pos === 'HIDDEN'}
                locked={products?.pos === 'DISABLED' || products?.pos === 'REQUESTED'}
              />
              <MenuDrawerItem
                index={36}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('posOpenNestedList', 36)}
                label="Order History"
                link="/point-of-sale-order-history"
                product="pos"
                hidden={products?.pos === 'HIDDEN'}
                locked={products?.pos === 'DISABLED' || products?.pos === 'REQUESTED'}
              />
            </MenuDrawerLinkCollapsedMenu>
            {/* Booking Settings  */}
            <MenuDrawerItem
              index={15}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleClick('bookingSettingsOpenNestedList', 15)}
              label="Booking Settings"
              icon="settingsIcon"
              collapsable
              collapsed={bookingSettingsOpenNestedList}
            />
            <MenuDrawerLinkCollapsedMenu collapsed={bookingSettingsOpenNestedList}>
              <MenuDrawerItem
                index={16}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('settingsOpenNestedList', 16)}
                label="Booking Modifiers"
                link="/booking-modifiers"
                product="facilities"
                hidden={products?.facilities === 'HIDDEN'}
                locked={products?.facilities === 'DISABLED' || products?.facilities === 'REQUESTED'}
              />
              <MenuDrawerItem
                index={18}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('settingsOpenNestedList', 18)}
                link="/forms"
                label="Forms"
                product="facilities"
                locked={!formsEnabled}
              />
            </MenuDrawerLinkCollapsedMenu>
            {/* Booking Settings End */}

            {/* Company Settings */}
            <MenuDrawerItem
              index={19}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleClick('settingsOpenNestedList', 19)}
              link="/company-settings"
              label="Company Settings"
              icon="settingsIcon"
              collapsable
              collapsed={settingsOpenNestedList}
            />
            <MenuDrawerLinkCollapsedMenu collapsed={settingsOpenNestedList}>
              <MenuDrawerItem
                index={20}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('settingsOpenNestedList', 20)}
                label="Company"
                link="/company-settings"
              />
              <MenuDrawerItem
                index={21}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('settingsOpenNestedList', 21)}
                link="/team-members"
                label="Dashboard Admins"
              />

              <MenuDrawerItem
                index={22}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('settingsOpenNestedList', 22)}
                label="Profile"
                link="/profile"
              />
              <MenuDrawerItem
                index={23}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('settingsOpenNestedList', 23)}
                label="Help"
                link="/help"
              />

              <MenuDrawerItem
                index={24}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => logoutTrigger()}
                label="Log Out"
              />
            </MenuDrawerLinkCollapsedMenu>
            {/* Company Settings End */}

            {/* Library */}
            <MenuDrawerItem
              index={25}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleLinkListItemClick('/messages', 25)}
              link="/library"
              label="Library"
              icon="libraryIcon"
            />
            {/* Library end */}

            <MenuDrawerItem
              index={26}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleLinkListItemClick('/messages', 26)}
              link="/messages"
              label="Messages"
              icon="inboxIcon"
              product="enquiries"
              locked={products.enquiries === 'DISABLED' || products.enquiries === 'REQUESTED'}
              hidden={products.enquiries === 'HIDDEN'}
            />

            <ConditionallyVisible
              condition={currentCompany && currentCompany.name && !currentCompany.isPowerleague}
            >
              <MenuDrawerItem
                index={27}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/referrals', 27)}
                link="/referrals"
                label="Referrals"
                icon="redeemIcon"
              />
            </ConditionallyVisible>
            <ConditionallyVisible
              condition={products?.lights === 'DISABLED' || products?.lights === 'REQUESTED'}
            >
              <MenuDrawerItem
                index={28}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/upsell', 28)}
                link="/upsell"
                label="Lights"
                icon="lightBulbIcon"
                product="lights"
                locked={products.lights === 'DISABLED' || products.lights === 'REQUESTED'}
              />
            </ConditionallyVisible>
            <ConditionallyVisible
              condition={products?.locks === 'DISABLED' || products?.locks === 'REQUESTED'}
            >
              <MenuDrawerItem
                index={29}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/upsell', 29)}
                link="/upsell"
                label="Locks"
                icon="lockIcon"
                product="locks"
                locked={products.locks === 'DISABLED' || products.locks === 'REQUESTED'}
              />
            </ConditionallyVisible>
            <ConditionallyVisible
              condition={products?.terminal === 'DISABLED' || products?.terminal === 'REQUESTED'}
            >
              <MenuDrawerItem
                index={30}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/upsell', 30)}
                link="/upsell"
                label="Card Terminal"
                icon="cardIcon"
                product="terminal"
                locked={products.terminal === 'DISABLED' || products.terminal === 'REQUESTED'}
              />
            </ConditionallyVisible>
            <ConditionallyVisible
              condition={products?.paidMemberships === 'DISABLED'}
            >
              <MenuDrawerItem
                index={31}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/upsell', 31)}
                link="/upsell"
                label="Paid Memberships"
                icon="peopleIcon"
                product="memberships"
                locked={products.paidMemberships === 'DISABLED'}
              />
            </ConditionallyVisible>
            <ConditionallyVisible
              condition={products?.sso === 'DISABLED' || products?.sso === 'REQUESTED'}
            >
              <MenuDrawerItem
                index={32}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/upsell', 32)}
                link="/upsell"
                label="Single Sign-On"
                icon="peopleIcon"
                product="sso"
                locked={products.sso === 'DISABLED' || products.sso === 'REQUESTED'}
              />
            </ConditionallyVisible>
            <ConditionallyVisible
              condition={products?.splitPayment === 'DISABLED' || products?.splitPayment === 'REQUESTED'}
            >
              <MenuDrawerItem
                index={33}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/upsell', 33)}
                link="/upsell"
                label="Split Payment"
                icon="cardIcon"
                product="splitPayment"
                locked={products.splitPayment === 'DISABLED' || products.splitPayment === 'REQUESTED'}
              />
            </ConditionallyVisible>
            <ConditionallyVisible
              condition={products?.whiteLabel === 'DISABLED' || products?.whiteLabel === 'REQUESTED'}
            >
              <MenuDrawerItem
                index={34}
                selectedIndex={selectedIndex}
                handleLinkListItemClick={() => handleLinkListItemClick('/upsell', 34)}
                link="/upsell"
                label="White Label"
                icon="whiteLabelIcon"
                product="whiteLabel"
                locked={products.whiteLabel === 'DISABLED' || products.whiteLabel === 'REQUESTED'}
              />
            </ConditionallyVisible>
            <Responsive maxDeviceWidth={600}>
              <UserMenu
                currentCompanyName={currentCompany.name}
                userCompanies={userCompanies}
                companyLogo={currentCompany.logoUrl}
                logoutTrigger={() => logoutTrigger()}
                updateCompany={(companyId) => updateUserCompany(companyId)}
                isMobile
              />
            </Responsive>

            <MenuDrawerItem
              index={35}
              selectedIndex={selectedIndex}
              handleLinkListItemClick={() => handleLinkListItemClick('/contact-us', 35)}
              link="/contact-us"
              label="Contact Us"
              icon="contactUsIcon"
              hidden={!currentCompany.onboarding?.contactUsEnabled}
            />
          </List>

          {/* Partner Page Button */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
          >
            <Button
              variant="contained"
              color="primary"
              href={partnerPageUrl}
              target="_blank"
              style={{ marginTop: '2rem' }}
            >
              <Typography
                variant="body2"
                style={{
                  display: 'flex', alignItems: 'center', gap: '0.5rem',
                }}
              >
                <ExitToAppIcon fontSize="medium" />
                Partner Page
              </Typography>
            </Button>
          </div>

        </Drawer>
        <main className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        >
          <ConditionallyVisible condition={warningMessageForAdmins && warningMessageOpen}>
            <WarningMessage
              message={warningMessageForAdmins}
              warningMessageForAdminsPersist={warningMessageForAdminsPersist}
              triggerClose={() => setWarningMessageOpen(false)}
            />
          </ConditionallyVisible>
          <PageRoutes />
        </main>
      </ConditionallyVisible>
    </div>
  );
};

MenuDrawer.propTypes = {
  logoutTrigger: PropTypes.func.isRequired,
  updateUserCompany: PropTypes.func.isRequired,
  menuDrawerOpen: PropTypes.bool.isRequired,
  appBarOnly: PropTypes.bool,
  warningMessageForAdmins: PropTypes.string,
  currentCompany: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  userCompanies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  warningMessageForAdminsPersist: PropTypes.bool,
  hasPendingMembershipRequests: PropTypes.bool,
};

MenuDrawer.defaultProps = {
  appBarOnly: false,
  warningMessageForAdmins: null,
  warningMessageForAdminsPersist: false,
  hasPendingMembershipRequests: false,
};

export default MenuDrawer;
