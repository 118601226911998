import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { DayPickerSingleDateController } from 'react-dates';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import * as reservationsActions from '../../../reducers/reservationsReducer';
import { updatePOSOrder, updatePOSTotal } from '../../../reducers/addonsReducer';
import { paymentMethodButtonsData } from '../../invoices/InvoiceRecordPaymentDialog';
import ToggleButtons from '../../../components/ToggleButtons';
import {
  useCompany, useMobile, useToast,
} from '../../../hooks';
import UserSelector from '../../../components/UserSelector';
import Terminal from '../../../components/Stripe/Terminal';
import ProductSelection from '../../pos/ProductSelection';
import { CardNotPresent } from './CardNotPresent';
import { ExcludeCalendarSlot } from './excludeCalendarSlot';

const RecordPaymentDialog = ({
  resetSelectedRows,
  deselectAll,
  singularBooking,
  calendarPayment = false,
  requestMultipleRecordPayment,
  buttonTitle,
  dialogTitle,
  subscriptionSelected,
  subscriptionLink,
  selectedReservations,
  subscription,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const toast = useToast();
  const {
    isPowerleague, timezone, currencySym, products, additionalPaymentMethods,
  } = useCompany();

  const reservation = useSelector((state) => state.reservation);
  const bookings = useSelector((state) => state.bookings);
  const { posOrder, posTotal } = useSelector((state) => state.addons);
  const [open, setOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const updateRecordPaymentStore = (keyValue) => dispatch(
    reservationsActions.updateRecordPaymentStore(keyValue),
  );

  const recordMultipleReservationPaymentReset = () => dispatch(
    reservationsActions.recordMultipleReservationPaymentReset(),
  );

  const handleRequestClose = () => {
    let { dates } = bookings;
    if (!dates.fromDate) {
      dates = {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().add(7, 'days').format('YYYY-MM-DD'),
      };
    }
    recordMultipleReservationPaymentReset();

    if (deselectAll) {
      deselectAll();
    }
    if (!singularBooking && !calendarPayment) {
      resetSelectedRows();
    }

    batch(() => {
      dispatch(reservationsActions.resetRecordPaymentStore());
      dispatch(updatePOSOrder([]));
      dispatch(updatePOSTotal(0));
    });

    setOpen(false);
    setButtonDisabled(false);
  };

  const performAction = () => {
    setButtonDisabled(true);
    requestMultipleRecordPayment(singularBooking);
  };

  const handlePaidAt = (date) => {
    updateRecordPaymentStore({
      paidAt: date.tz(timezone).format(),
    });
  };

  const {
    reservationsPaymentCreationSuccess, reservationsPaymentCreationError, recordPaymentBookings,
  } = reservation;
  const {
    paymentType, paymentMethod, paidAt, user, numberOfPlayers, amount, note, isAnonymousPayment,
  } = recordPaymentBookings;

  let paymentTypes = [
    {
      buttonTitle: 'Full Balance',
      buttonValue: 'FULL_AMOUNT',
    },
  ];

  const isMultiplePayment = selectedReservations?.length > 1;
  let isReservationPayment = selectedReservations?.every((x) => x.type === 'RESERVATION');
  const isPOSReservationPayment = selectedReservations?.every((x) => x.type === 'POS');
  let isSubscriptionPayment = selectedReservations?.every((x) => x.type === 'SUBSCRIPTION');

  const booking = selectedReservations?.length === 1 ? selectedReservations[0] : null;
  if (booking) {
    isReservationPayment = booking?.type === 'RESERVATION';
    isSubscriptionPayment = booking?.type === 'SUBSCRIPTION';
  }

  const isPartialBookingsEnabled = products?.partialPayments === 'ENABLED' && booking && booking.type !== 'POS';
  const isTerminalEnabled = products?.terminal === 'ENABLED';

  if (isPartialBookingsEnabled) {
    paymentTypes = [
      {
        buttonTitle: 'Full Balance',
        buttonValue: 'FULL_AMOUNT',
      },
      {
        buttonTitle: 'Custom Amount',
        buttonValue: 'CUSTOM_AMOUNT',
      },
      {
        buttonTitle: 'Equal Split',
        buttonValue: 'SPLIT_AMOUNT',
      },
    ];
  }

  const isBookingPaid = useMemo(() => {
    if (selectedReservations?.some((x) => x.paid)) {
      return true;
    }

    return false;
  }, [selectedReservations]);

  const isUnconfirmedSubscription = useMemo(() => {
    if (selectedReservations?.some((x) => x.unconfirmedSubscription)) {
      return true;
    }

    return false;
  }, [selectedReservations]);

  const isBookingProcessing = useMemo(() => {
    if (selectedReservations?.some((x) => x.status === 'PENDING')) {
      return true;
    }

    return false;
  }, [selectedReservations]);

  const isFormValid = useMemo(() => {
    if (
      booking
      && amount > (booking.totalPaid ? (booking.total - booking.totalPaid) : booking.total)
    ) {
      return false;
    }

    if (paymentMethod === 'TERMINAL') {
      return false;
    }

    if (paymentType !== 'FULL_AMOUNT') {
      if (!amount) {
        return false;
      }

      if (paymentType === 'SPLIT_AMOUNT') {
        if ((!user && !isAnonymousPayment) || !numberOfPlayers) {
          return false;
        }
      }
    }

    return true;
  }, [paymentMethod, user, amount, numberOfPlayers, paidAt, isAnonymousPayment]);

  useEffect(() => {
    if (calendarPayment) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    updateRecordPaymentStore({
      reservationId: booking?.id ?? null,
    });

    setButtonDisabled(false);
  }, [booking]);

  useEffect(() => {
    if (paymentType === 'SPLIT_AMOUNT' && amount) {
      const leftToPay = booking.total - (booking.totalPaid ?? 0);
      const newAmount = (Math.ceil(Number(booking.total / numberOfPlayers) * 100) / 100).toFixed(2);

      updateRecordPaymentStore({
        amount: newAmount < leftToPay ? newAmount : leftToPay.toFixed(2),
      });
    }
  }, [booking, paymentType, numberOfPlayers]);

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        {buttonTitle}
      </Button>
      <Dialog open={open} fullScreen={isMobile} fullWidth maxWidth="md">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              flex: '1 1 auto',
            }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>

        {(isBookingPaid || isBookingProcessing || isUnconfirmedSubscription) ? (
          <>
            <DialogContent>
              <Typography variant="h5" color="secondary" gutterBottom={1}>
                {(() => {
                  if (isBookingPaid) {
                    return 'This booking has already been paid for';
                  }
                  if (isBookingProcessing) {
                    return 'This booking is currently processing payment';
                  }
                  if (isUnconfirmedSubscription) {
                    return 'This booking has an unconfirmed subscription';
                  }
                  return 'Payment status unknown.';
                })()}
              </Typography>

              <Typography>
                {isBookingPaid && 'One or more of the bookings that you have selected have already been paid for.'}
                {isBookingProcessing && 'The payment for this booking is currently processing. Please wait for confirmation.'}
                {isUnconfirmedSubscription && (
                  'The booking includes a subscription that has not been confirmed by the booker yet. Please wait for the booker to complete their subscription, which will ensure automatic payment. If the subscription is not completed by the time of the slot, you will be able to record the payment manually.'
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <ConditionallyVisible condition={reservationsPaymentCreationSuccess === null}>
              <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>

                {/* Totals */}
                {isPartialBookingsEnabled && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '1rem' }}>
                    <div style={{ textAlign: 'center' }}>
                      <DialogContentText style={{ marginBottom: 0.1 }}>
                        Total
                      </DialogContentText>
                      <Typography>
                        {`${currencySym}${booking?.total?.toFixed(2)}`}
                      </Typography>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <DialogContentText style={{ marginBottom: 0.1 }}>
                        Paid
                      </DialogContentText>
                      <Typography>
                        {`${currencySym}${(booking.totalPaid ? booking.totalPaid : 0).toFixed(2)}`}
                      </Typography>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <DialogContentText style={{ marginBottom: 0.1 }}>
                        Unpaid
                      </DialogContentText>
                      <Typography>
                        {`${currencySym}${(booking?.totalPaid ? (booking?.total - booking?.totalPaid) : booking?.total)?.toFixed(2)}`}
                      </Typography>
                    </div>
                  </div>
                </>
                )}

                <div>
                  <DialogContentText style={{ marginBottom: 0.1 }}>
                    Payment Type
                  </DialogContentText>
                  <ToggleButtons
                    buttonsData={paymentTypes}
                    changeOption={(option) => updateRecordPaymentStore({
                      paymentType: option,
                      user: null,
                      amount: null,
                      numberOfPlayers: null,
                      isAnonymousPayment: false,
                      note: null,
                    })}
                    value={paymentType}
                  />
                </div>

                <ProductSelection paymentMethod={paymentMethod} />

                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  {paymentType === 'SPLIT_AMOUNT' && (
                  <>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                      <PBInput
                        label="How many players?"
                        value={numberOfPlayers}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          let amount = Number(booking.total / value).toFixed(2);

                          if (amount > (booking.total - booking.totalPaid)) {
                            amount = (booking.total - booking.totalPaid).toFixed(2);
                          }

                          updateRecordPaymentStore({
                            numberOfPlayers: value,
                            amount,
                          });
                        }}
                      />
                      <PBInput
                            // label="Amount"
                        value={amount}
                        onChange={(e) => {
                          updateRecordPaymentStore({
                            amount: e.target.value,
                          });
                        }}
                        isError={
                              amount > (booking.totalPaid
                                ? (booking.total - booking.totalPaid)
                                : booking.total)
                            }
                        errorMessage={amount > (
                          booking.totalPaid
                            ? (booking.total - booking.totalPaid)
                            : booking.total
                        ) ? 'Amount cannot be greater than amount owed' : null}
                        disabled
                      />
                    </div>

                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={isAnonymousPayment ?? false}
                            onChange={(e) => {
                              updateRecordPaymentStore({
                                isAnonymousPayment: e.target.checked,
                                user: null,
                              });
                            }}
                          />
                          )}
                        label="Is Non-User Payment?"
                      />
                    </FormGroup>

                    {(user || isAnonymousPayment) ? (
                      <div>
                        {!isAnonymousPayment ? (
                          <>
                            <DialogContentText style={{ marginBottom: 0.1 }}>
                              Selected Customer
                            </DialogContentText>
                            <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                            {user.teamName && <Typography>{`${user.teamName}`}</Typography>}
                            <Button
                              variant="contained"
                              style={{ marginTop: '1rem' }}
                              onClick={() => {
                                updateRecordPaymentStore({
                                  user: null,
                                });
                              }}
                            >
                              Change Customer
                            </Button>
                          </>
                        ) : (
                          <>
                            <DialogContentText style={{ marginBottom: 0.1 }}>
                              Non-User Payment
                            </DialogContentText>
                            <Typography variant="body2">
                              This payment will be recorded as a non-user payment, you can record a
                              name in the note field.
                            </Typography>
                          </>
                        )}
                      </div>
                    ) : (
                      <UserSelector
                        onChange={(e) => updateRecordPaymentStore({
                          user: e,
                        })}
                        user={user}
                      />
                    )}
                  </>
                  )}

                  {paymentType === 'CUSTOM_AMOUNT' && (
                  <>
                    <PBInput
                      label="Amount"
                      value={amount}
                      onChange={(e) => {
                        updateRecordPaymentStore({
                          amount: e.target.value,
                        });
                      }}
                      isError={
                      amount > (booking.totalPaid
                        ? (booking.total - booking.totalPaid)
                        : booking.total)
                    }
                      errorMessage={amount > (
                        booking.totalPaid
                          ? (booking.total - booking.totalPaid) : booking.total
                      ) ? 'Amount cannot be greater than amount owed' : null}
                    />
                  </>
                  )}

                  {/* Payment Method Selection */}
                  {(paymentType !== 'SPLIT_AMOUNT' || (paymentType === 'SPLIT_AMOUNT' && user) || (paymentType === 'SPLIT_AMOUNT' && isAnonymousPayment)) && (
                  <>
                    <PBInput
                      label="Note"
                      type="textarea"
                      value={note}
                      onChange={(e) => {
                        updateRecordPaymentStore({
                          note: e.target.value,
                        });
                      }}
                    />

                    <div>
                      <DialogContentText>
                        Select payment method:
                      </DialogContentText>
                      <ToggleButtons
                        buttonsData={
                          paymentMethodButtonsData(
                            !subscriptionSelected && paymentType === 'FULL_AMOUNT',
                            isPowerleague,
                            isTerminalEnabled,
                            additionalPaymentMethods,
                            products?.cardNotPresent === 'ENABLED',
                          )
                        }
                        changeOption={(option) => updateRecordPaymentStore({
                          paymentMethod: option,
                        })}
                        value={paymentMethod}
                      />
                    </div>
                    {
                      paymentMethod === 'TERMINAL'
                      && (paymentType === 'FULL_AMOUNT' || (paymentType !== 'FULL_AMOUNT' && amount))
                      && (isReservationPayment || isSubscriptionPayment
                         || isMultiplePayment || isPOSReservationPayment) && (
                         <div style={{ display: 'flex', marginTop: '2rem' }}>
                           {isMultiplePayment ? (
                             <Terminal
                               transactionType="MULTIPLE_PAYMENT"
                               transactionId={null}
                               metadata={{
                                 reservations: selectedReservations.map((x) => ({
                                   id: x.id,
                                   type: x.type,
                                   slot: x.slot,
                                 })),
                                 isAnonymousPayment,
                                 note,
                                 posOrder,
                               }}
                               userId={user?.id ?? null}
                               amount={
                                selectedReservations.reduce((acc, x) => acc + x.total, 0)
                              }
                               onSuccess={() => {
                                 toast.trigger({
                                   message: 'Payment Successful!',
                                   type: 'success',
                                 });

                                 updateRecordPaymentStore({
                                   note: null,
                                 });

                                 handleRequestClose();
                                 selectedReservations.forEach((x) => {
                                   dispatch(reservationsActions.fetchReservationSelectedRow(x.id));
                                 });
                               }}
                             />
                           ) : (
                             <Terminal
                               transactionType={
                              isReservationPayment ? 'RESERVATION_PAYMENT' : 'SUBSCRIPTION_PAYMENT'
                            }
                               transactionId={booking?.id}
                               metadata={
                              {
                                ...(isSubscriptionPayment ? {
                                  slot: booking.slot,
                                } : {
                                }),
                                isAnonymousPayment,
                                note,
                                posOrder,
                              }
                            }
                               userId={user?.id ?? booking?.userId}
                               amount={amount}
                               onSuccess={() => {
                                 toast.trigger({
                                   message: 'Payment Successful!',
                                   type: 'success',
                                 });

                                 if (paymentType === 'CUSTOM_AMOUNT') {
                                   handleRequestClose();
                                 } else {
                                   // Set the user back to null to take another payment
                                   updateRecordPaymentStore({
                                     user: null,
                                   });
                                   dispatch(
                                     reservationsActions.fetchReservationSelectedRow(booking.id),
                                   );
                                 }
                               }}
                             />
                           )}

                         </div>
                      )
                    }

                    <CardNotPresent
                      paymentMethod={paymentMethod}
                      booking={booking}
                      onSuccess={() => {
                        toast.trigger({
                          message: 'Payment Successful!',
                          type: 'success',
                        });
                        handleRequestClose();
                        dispatch(
                          reservationsActions.fetchReservationSelectedRow(booking.id),
                        );
                      }}
                    />

                    <ConditionallyVisible condition={paymentMethod !== 'NOT_CHARGED' && paymentMethod !== 'TERMINAL' && paymentMethod !== 'CARD_NOT_PRESENT'}>
                      <div>
                        <DialogContentText>
                          Select payment date:
                        </DialogContentText>
                        <DayPickerSingleDateController
                          onDateChange={(date) => handlePaidAt(date)}
                          date={paidAt !== null ? moment(paidAt) : moment()}
                          noBorder
                        />
                      </div>
                    </ConditionallyVisible>
                  </>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <div>
                  <ConditionallyVisible condition={paymentType !== 'FULL_AMOUNT'}>
                    <p>
                      Paying Total Amount:
                      {` ${currencySym}${(Number((amount || 0)) + Number(posTotal)).toFixed(2)}`}
                    </p>
                  </ConditionallyVisible>
                  <ConditionallyVisible condition={paymentType === 'FULL_AMOUNT'}>
                    {isMultiplePayment ? (
                      <p>
                        Paying Total Amount:
                        {` ${currencySym}${(selectedReservations.reduce((acc, x) => acc + x.total, 0) + Number(posTotal))?.toFixed(2)}`}
                      </p>
                    ) : (
                      <p>
                        Paying Total Amount:
                        {` ${currencySym}${(booking?.totalPaid ? (booking?.total - booking?.totalPaid) + Number(posTotal) : booking?.total + Number(posTotal))?.toFixed(2)}`}
                      </p>
                    )}
                  </ConditionallyVisible>
                </div>
                {calendarPayment ? (
                  <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                    Close
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                    Go back
                  </Button>
                )}
                {subscriptionLink && (
                <Link to={subscriptionLink}>
                  <Button variant="contained" color="secondary">
                    View Subscription
                  </Button>
                </Link>
                )}

                {subscriptionSelected && subscription && (
                <ExcludeCalendarSlot subscription={subscription} />
                )}

                {paymentMethod !== 'CARD_NOT_PRESENT' && (
                <Button
                  id="manager-bookings-record-payment"
                  disabled={buttonDisabled || !isFormValid}
                  variant="contained"
                  onClick={() => performAction()}
                  color="primary"
                >
                  Record Payment
                </Button>
                )}
              </DialogActions>
            </ConditionallyVisible>

            <ConditionallyVisible condition={reservationsPaymentCreationSuccess === false}>
              <DialogContent>
                <DialogContentText>
                  <div style={{ color: '#ac372f', padding: '45px' }}>Error! There was a problem recording payment for these reservations.</div>
                  <ConditionallyVisible condition={reservationsPaymentCreationError !== null}>
                    <div style={{ color: '#ac372f', padding: '45px' }}>{reservationsPaymentCreationError}</div>
                  </ConditionallyVisible>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </ConditionallyVisible>

            <ConditionallyVisible
              condition={reservationsPaymentCreationSuccess !== null
              && reservationsPaymentCreationSuccess}
            >
              <DialogContent>
                <DialogContentText>
                  <div style={{ color: '#47FEB4', padding: '45px' }}>Success! Payment recorded</div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => handleRequestClose()} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </ConditionallyVisible>
          </>
        )}

      </Dialog>
    </div>
  );
};

RecordPaymentDialog.propTypes = {
  deselectAll: PropTypes.func.isRequired,
  requestMultipleRecordPayment: PropTypes.func.isRequired,
  resetSelectedRows: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  singularBooking: PropTypes.bool.isRequired,
  subscriptionSelected: PropTypes.bool,
  calendarPayment: PropTypes.bool,
  subscriptionLink: PropTypes.string,
  selectedReservations: PropTypes.arrayOf({}).isRequired,
  subscription: PropTypes.shape(),
};

RecordPaymentDialog.defaultProps = {
  subscriptionSelected: false,
  calendarPayment: false,
  subscriptionLink: null,
  subscription: null,
};

export default RecordPaymentDialog;
