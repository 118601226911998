/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog, DialogActions,
  DialogContent, DialogTitle,
  List, ListItem, ListItemAvatar,
  ListItemSecondaryAction, ListItemText,
  Avatar,
  Tabs, Tab,
  IconButton,
  FormControl, FormControlLabel,
  Checkbox,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/NotInterested';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AddMemberRow from './AddMemberRow';
import * as membershipsActions from '../../../reducers/accessRestrictionsReducer';
import * as userActions from '../../../reducers/usersReducer';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';

const styles = () => ({
  newUserForm: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  formNames: {
    display: 'flex',
  },
  formControl: {
    marginRight: 20,
    marginTop: 20,
  },
  checkboxControl: {
    marginTop: 10,
  },
});

const initialState = () => ({
  open: false,
  currentTab: 0,
  queuedUsers: [],
  checkedEmailConfirm: false,
  firstName: '',
  lastName: '',
  email: '',
  teamName: '',
  phone: '',
  dialCode: '',
  siteName: 'None Fetched',
  term: null,
});

class AddMemberDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState(), dialCode: this.props.companyInfo.dialcode || '44' };
    const searchAPIDebounced = AwesomeDebouncePromise(this.fireOffSearch, 800);
    this.searchAPIDebounced = searchAPIDebounced;
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ ...initialState() });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  fireOffSearch = async (searchTerm) => {
    const { getUsers } = this.props;
    getUsers(0, searchTerm);
    this.setState({ term: searchTerm });
  };

   handleUserSearch = (value) => {
     this.searchAPIDebounced(value);
   };

  addUser = () => {
    const reqBody = {
      users: this.state.queuedUsers,
    };
    this.props.addUsersToMembership(this.props.membershipID, reqBody);

    this.handleRequestClose();
  }

  inviteUser = () => {
    // TODO Set company name
    const membershipId = this.props.membershipID;
    const reqBody = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      teamName: this.state.teamName,
      phone: this.state.phone,
      isVerified: false,
      dialCode: this.state.dialCode,
    };
    this.props.inviteUserToMembership(membershipId, reqBody);
    this.handleRequestClose();
  }

  // eslint-disable-next-line consistent-return
  queueExistingUser = (userEntry, userList) => {
    if (userEntry == null) { return null; }
    const queuedUsers = this.state.queuedUsers.slice();
    const userData = userList.filter(
      (userObject) => (userObject.label === userEntry.label),
    );

    if (userData && userData.length > 0) {
      let userExists = false;
      queuedUsers.forEach((queuedUser) => {
        if (queuedUser.id === userData[0].id) {
          userExists = true;
        }
      });
      if (!userExists) {
        queuedUsers.push(userData[0]);
        this.setState({ queuedUsers });
      }
    }
  }

  removeQueuedUser = (userID) => {
    let queuedUsers = this.state.queuedUsers.slice();
    queuedUsers = queuedUsers.filter((queuedUser) => (queuedUser.id !== userID));
    this.setState({ queuedUsers });
  }

  render() {
    const {
      classes, usersList, selectUserForInvoice, addUsers, closeDialog,
    } = this.props;
    const { isLoading, term } = this.state;

    let userList = [];
    if (usersList && usersList.length > 0) {
      userList = usersList.map((user) => ({
        label: `${user.firstName} ${user.lastName} <${user.email}>`,
        fullName: `${user.firstName} ${user.lastName}`,
        email: `${user.email}`,
        id: `${user.id}`,
      }));
    }

    const queuedUsers = this.state.queuedUsers.map((queuedUser) => (
      <ListItem button>
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={queuedUser.fullName}
          secondary={queuedUser.email}
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="Delete"
            onClick={() => this.removeQueuedUser(queuedUser.id)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));
    const queueLength = this.state.queuedUsers.length;
    const dialogActionLabel = selectUserForInvoice > 0 ? 'SEND INVOICE' : 'MAKE MEMBER';
    const existingUserTab = (
      <div>
        <Typography gutterBottom type="subheading">
          Search for an existing customer:
        </Typography>
        <Autocomplete
          className="auto-complete-combo"
          options={userList}
          getOptionLabel={(option) => option.label}
          style={{ width: 300, margin: 'auto' }}
          disableClearable
          onChange={(event, option) => this.queueExistingUser({ ...option }, userList)}
          renderInput={(params) => (
            <TextField
              {...params}
              defaultValue={term}
              label="Select User"
              variant="outlined"
              fullWidth
              onChange={(event) => this.handleUserSearch(event.target.value)}
            />
          )}
        />
        <List dense>
          {queuedUsers}
        </List>
        {queueLength > 0
          && (
            <Typography style={{ marginTop: 10 }} gutterBottom type="body2">
              Add
              {' '}
              {queueLength === 1 ? 'this user' : 'these users'}
              {' '}
              by pressing
              {' '}
              {dialogActionLabel}
              {' '}
              below.
              {queueLength === 1
                && (
                  <span>
                    <br />
                    Or search and add more first.
                  </span>
                )}
            </Typography>
          )}
      </div>
    );

    const newUserTab = (
      <div>
        <Typography type="subheading">
          Invite a new user (who is not already a customer):
        </Typography>
        <Typography gutterBottom color="secondary" type="body2">
          This will automatically send an email to them.
        </Typography>
        <div className={classes.newUserForm}>
          <div className={classes.formNames}>
            <FormControl className={classes.formControl}>
              <PBInput
                id="firstName"
                type="text"
                label="First Name"
                value={this.state.firstName}
                onChange={this.handleChange('firstName')}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <PBInput
                id="lastName"
                type="text"
                label="Last Name"
                value={this.state.lastName}
                onChange={this.handleChange('lastName')}
              />
            </FormControl>
          </div>
          <div className={classes.formNames}>
            <FormControl className={classes.formControl}>
              <PBInput
                id="email"
                type="email"
                label="Email Address"
                value={this.state.email}
                onChange={this.handleChange('email')}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <PBInput
                id="teamName"
                type="text"
                label="Team name"
                value={this.state.teamName}
                onChange={this.handleChange('teamName')}
              />
            </FormControl>
          </div>
          <div>
            <FormControl className={classes.formControl}>
              <PBInput
                id="phone"
                type="phone"
                value={{ phone: this.state.phone, dialCode: this.state.dialCode }}
                onChange={this.handleChange('phone')}
                onChangeDialcode={this.handleChange('dialCode')}
              />
            </FormControl>
          </div>
          <FormControlLabel
            className={classes.checkboxControl}
            control={(
              <Checkbox
                checked={this.state.checkedEmailConfirm}
                onChange={(event) => this.setState({ checkedEmailConfirm: event.target.checked })}
                value="checkedEmailConfirm"
              />
            )}
            label="I confirm that I have permission to email this person at the address provided"
          />
        </div>
      </div>
    );

    let tabContent = null;
    let dialogActions = null;
    const actionLabel = selectUserForInvoice ? 'Send Invoice' : 'Make Member';
    switch (this.state.currentTab) {
      case 1: {
        const {
          firstName,
          lastName,
          email,
          checkedEmailConfirm,
        } = this.state;
        const elementsComplete = (
          firstName.length > 0
          && lastName.length > 0
          && email.length > 0 && email.includes('@') && email.includes('.')
          && email[email.length - 1] !== '.'
          && checkedEmailConfirm
        );

        tabContent = newUserTab;
        dialogActions = (
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cancel
            </Button>
            <Button
              id="manager-users-invite-membership"
              disabled={!elementsComplete}
              onClick={this.inviteUser}
              color="primary"
            >
              Invite to Membership
            </Button>
          </DialogActions>
        );
        break;
      }
      case 0:
      default:
        tabContent = existingUserTab;
        dialogActions = (
          <DialogActions>
            <Button onClick={selectUserForInvoice ? closeDialog : this.handleRequestClose} color="secondary">
              Cancel
            </Button>
            <Button disabled={queueLength < 1} onClick={selectUserForInvoice ? () => addUsers(this.state.queuedUsers) : this.addUser} color="primary">
              {actionLabel}
              {queueLength > 1 && 's'}
            </Button>
          </DialogActions>
        );
    }

    const isMobile = window.innerWidth < 768;
    const label = selectUserForInvoice ? 'Send Invoice to Additional User' : `Add Members to ${this.props.membershipName}`;
    return (
      <div>
        <AddMemberRow didClick={this.handleClickOpen} />
        <Dialog
          open={this.state.open || this.props.open}
          onRequestClose={selectUserForInvoice ? closeDialog : this.handleRequestClose}
          maxWidth={false}
          fullScreen={isMobile}
        >
          <LinearProgress
            color="primary"
            variant={isLoading ? 'determinate' : 'indeterminate'}
            value={100}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle color="primary">{label}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon
                onClick={selectUserForInvoice ? closeDialog : this.handleRequestClose}
              />
            </DialogActions>
          </div>
          <Tabs
            value={this.state.currentTab}
            onChange={(event, newTab) => this.setState({ currentTab: newTab })}
            fullWidth
            indicatorColor="primary"
            textColor="primary"
            style={{
              minWidth: 700,
              marginBottom: 30,
            }}
          >
            <Tab icon={<PersonIcon />} label="Existing User" />
            <ConditionallyVisible condition={!selectUserForInvoice}>
              <Tab
                icon={<PersonAddIcon />}
                label="New User"
                onClick={() => this.setState({ currentTab: 1 })}
              />
            </ConditionallyVisible>
          </Tabs>
          <DialogContent
            style={{
              minWidth: 700,
              minHeight: 300,
            }}
          >
            {tabContent}
          </DialogContent>
          {dialogActions}
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { memberships, users, companies } = state;
  const usersList = users.users;
  const { companyInfo } = companies;
  return {
    memberships,
    usersList,
    companyInfo,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addUsersToMembership: (membershipId, reqBody) => dispatch(
    membershipsActions.addUsersToMembership(membershipId, reqBody),
  ),
  inviteUserToMembership: (membershipId, reqBody) => dispatch(
    membershipsActions.inviteUserToMembership(membershipId, reqBody),
  ),
  getUsers: (page, term) => dispatch(userActions.getUsers(page, term)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddMemberDialog));
